const Hero = () => {
  return (
    <section className="hero">
      <div className="hero-body">
        <div className="container">
          <h1 className="title">
            <strong>Marvin</strong> Baltazar
          </h1>
          <h2 className="subtitle">
            Engineering/Development Lead
          </h2>
        </div>
      </div>
    </section>
  )
}

export default Hero;
