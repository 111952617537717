import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './css/bulma.css';
import './css/profile_timeline.scss';
import './css/profile.scss';
import Home from './home/Index';
import Resume from './resume/Index';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/resume" element={<Resume />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
