import ExtLink from '../../resume/components/Link';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-menu is-active">
        <div className="navbar-end">
          <Link to="/resume" className="navbar-item">Work</Link>
          <ExtLink href="https://techandfi.com" className="navbar-item">Writing</ExtLink>
        </div>
      </div>
    </nav>
  )
}

export default Navbar;
