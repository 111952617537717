const Link = (props) => {
  const { href, className, children } = props;

  return (
    <a
      href={href}
      className={className}
      target='_blank'
      rel='noreferrer'
    >
      {children}
    </a>
  )

}

export default Link;
