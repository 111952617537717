import React from 'react';
import { Link } from 'react-router-dom';
import ExtLink from './../resume/components/Link';
import Header from './components/Header';

const Index = () => {
  return (
    <>
      <div className="content container-home">
        <Header />
        <p>
          My name is Marvin Baltazar, and my friends call me <span className="has-text-weight-semibold">Marvs</span>.
        </p>
        <p>
          An Electronics Engineer turned Software Engineer, I specialize in turning ideas into real-world applications. That involves designing, launching, growing, and maintaining products that benefit people.
        </p>
        <p>
          My work experience led me to be proficient at being an Engineering Leader, with extensive work experience in Backend development. I was the founding engineer and Development Lead of <ExtLink href="https://www.crunchbase.com/organization/launchpad-4">LaunchPad Recruits</ExtLink>, where I worked for 9 years from the beginning until the company was acquired.
        </p>
        <p>
          You can find the details of my professional experience <Link to='/resume'>here</Link>.
        </p>
        <p>
          I also regularly write articles at the <span className="is-italic">Technology and Financial Independence</span> blog in <ExtLink href="https://techandfi.com">techandfi.com</ExtLink>. These are mostly on the topic of personal development, software development, and personal finance.
        </p>
      </div>
    </>
  )
}

export default Index;
