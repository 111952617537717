import Skills from './Skills';
import Technologies from './Technologies';
import Education from './Education';

const SkillsSidebar = () => {
  return (
    <section className="section skill-cards">
      <Skills/>
      <Technologies/>
      <Education/>
    </section>
  )
}

export default SkillsSidebar;
