const TimelineItem = (props) => {
  const { date, children } = props;
  return (
    <div className='timeline-item' date-is={date} >
      {children}
    </div>
  )
}

export default TimelineItem;
