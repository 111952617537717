import Navbar from './Navbar';

const Header = () => {
  return (
    <nav className="level">
      <div className="level-left">
        <div className="level-item">
          <h1>Hello!</h1>
        </div>
      </div>
      <div className="level-right">
        <div className="level-item">
          <Navbar />
        </div>
      </div>
    </nav>
  )
}

export default Header;
