import TimelineItem from './TimelineItem';

const Timeline = () => {
  return (
    <section className='section timeline'>
      <div className='container'>
        <p className='is-size-4'>Work Experience</p>
        <div className='timeline-container'>
          <TimelineItem date='October 2021 - Present'>
            <p className='company'>Empire Flippers</p>
            <p className='position'>Lead Backend Engineer (Nov 2023 - Present)</p>
            <p className='position'>Senior Backend Engineer (Oct 2021 - Nov 2023)</p>
            <ul>
              <li>Develop and maintain application features using Ruby on Rails and ReactJS.</li>
              <li>Build and support the platform integration with Amazon's Seller Central API and Shopify API.</li>
              <li>Monitor and resolve application performance issues.</li>
              <li>Perform code reviews and keep the backend code performant and secure.</li>
              <li>Provide Tier 3 support for application issues.</li>
            </ul>
          </TimelineItem>

          <TimelineItem date='September 2020 - October 2021'>
            <p className='company'>Outmatch, Inc. (now Harver)</p>
            <p className='position'>Engineering Lead</p>
            <ul>
              <li>Led a fully-remote, autonomous team of Developers and Quality Assurance.</li>
              <li>Performed code reviews and coordinated daily standups, sprint planning and estimations.</li>
              <li>Created technical documentation for new features to guide development.</li>
              <li>Responsible for bi-weekly product releases and deployments.</li>
              <li>Worked on moving a monolithic Rails application into a containerized production architecture. This was done using AWS ECS, Fargate and Aurora.</li>
              <li>Planned and implemented the migration of an entire Rails application into the latest framework version.</li>
              <li>Supported integration of the video interview platform with other company platforms using a REST API with a Ruby on Rails backend.</li>
              <li>Improved the team's branching strategy, integrated DAST, and reduced the CI/CD pipeline duration.</li>
            </ul>
          </TimelineItem>

          <TimelineItem date='January 2012 - September 2020'>
            <p className='company'>LaunchPad Recruits, Ltd.</p>
            <p className='position'>Development Lead</p>
            <ul>
              <li>As the founding engineer, helped build and evolve the company from a Video Interview solution to a Recruitment Process Automation platform.</li>
              <li>Developed a significant portion of the application code using the Ruby on Rails framework, deploying in AWS.</li>
              <li>Designed and developed a REST API to allow integration with enterprise Applicant Tracking Systems.</li>
              <li>Increased the company's client base by designing and developing a feature to automatically screen candidates based on specific criteria.</li>
              <li>Designed and developed the company's GDPR solution for data privacy and compliance.</li>
              <li>Developed the video transcoding and backup pipeline that processed more than 8 million videos, in use for almost a decade.</li>
              <li>Reduced the loading time of candidate review pages by 75% by building an in-house video transcoding service, done using ffmpeg, AWS S3 and a Rails API.</li>
              <li>Improved the development team experience by developing an internal video transcoding application to provide a locally available service for feature development.</li>
              <li>Responsible for bi-weekly product releases and deployments.</li>
              <li>Main point of contact for troubleshooting and resolving production issues.</li>
              <li>Planned and led the migration and update of production servers with minimal impact to customers.</li>
              <li>Maintained the security of the application, ensured GDPR and PCI compliance, and resolved penetration testing issues.</li>
            </ul>
          </TimelineItem>

          <TimelineItem date='June 2011 - January 2012'>
            <p className='company'>SourcePad, Inc.</p>
            <p className='position'>Ruby on Rails Developer</p>
            <ul>
              <li>NY-based consulting company with a focus on developing web and mobile applications for startups.</li>
              <li>Developed web applications for US and EU startups using Ruby on Rails and JavaScript, deploying in AWS.</li>
              <li>Designed and developed an internal tool to automatically identify calendar availability using the Google Calendar API.</li>
            </ul>
          </TimelineItem>

          <TimelineItem date='2008 - 2011'>
            <p className='company'>Astra (Philippines), Inc.</p>
            <p className='position'>Senior Software Engineer</p>
            <ul>
              <li>Managed a team of developers for a University Management System web application.</li>
              <li>Provided technical support for clients and performed on-premise server setup and maintenance.</li>
              <li>Designed and developed features for a Human Resource Information System web application.</li>
              <li>Provided training and guidance to other engineers through meetings and code reviews.</li>
            </ul>
          </TimelineItem>

          <TimelineItem date='2005 - 2008'>
            <p className='company'>Canon Information Technologies (Philippines), Inc.</p>
            <p className='position'>Hardware Engineer</p>
            <ul>
              <li>Performed design verification of hardware modules used in Canon's multi-function printers.</li>
              <li>Created patent applications relating to electronics technology.</li>
              <li>Provided training and guidance to other engineers through face-to-face lectures, training materials, and code/document reviews.</li>
            </ul>
          </TimelineItem>
        </div>
      </div>
    </section>
  )
}

export default Timeline;
