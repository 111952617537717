import React from 'react';
import Hero from './components/Hero';
import Summary from './components/Summary';
import SkillsSidebar from './components/SkillsSidebar';
import OtherProjects from './components/OtherProjects';
import Recommendations from './components/Recommendations';
import Timeline from './components/Timeline';

const Index = () => {
  return (
    <>
      <Hero />
      <Summary />
        <div className="container">
          <div className="columns">
            <div className="column is-8">
              <Timeline />
            </div>
            <div className="column is-4">
              <SkillsSidebar />
            </div>
          </div>
        </div>
      <OtherProjects />
      <Recommendations />
    </>
  )
}

export default Index;
