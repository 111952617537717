const Summary = () => {
  const currentYear = new Date().getFullYear();
  const leadYears = currentYear - 2010;

  return (
    <section className="hero summary">
      <div className="hero-body">
      <div className="container">
        <p>
          Solid yet versatile Engineer with more than {leadYears} years
          experience in developing web application projects and leading remote teams. I have
          built and managed systems from MVPs to full-featured products with
          millions of users. Self-motivated and highly adept at remote work.
        </p>
      </div>
      </div>
    </section>
  );
};

export default Summary;
