const Technologies = () => {
  return (
    <div class='content'>
      <hr/>
      <p className='is-size-4'>Technologies</p>
      <ul>
        <li>Ruby, Rails, ReactJS, JavaScript, Python</li>
        <li>PostgreSQL, MySQL, Redis</li>
        <li>AWS, Docker, Heroku</li>
      </ul>
    </div>
  )
}

export default Technologies;
