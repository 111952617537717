import Link from './Link';

const Education = () => {
  return (
    <div class='content'>
      <hr/>
      <p className='is-size-4'>Education</p>
      <p>
        <span className='is-size-6'>BS Electronics and Communications Engineering</span>
        <br/>
        <span className='is-italic'>
          University of the Philippines
        </span>
        <br/>
        2000-2005
      </p>
      <p>
        Licensed Electronics Engineer
        <br/>
        2005-2011
      </p>
      <p>
        <Link href='https://credentials.edx.org/credentials/862e2f18b0b84feca2193641e771cbe0/'>
          Foundations of Data Science
        </Link>
        <br/>
        <span className='is-italic'>
          Professional Certificate
        </span>
        <br/>
        BerkeleyX
      </p>
    </div>
  )
}

export default Education;
