import ExtLink from './Link';

const Recommendations = () => {
  return (
    <section className="section recommendations">
      <div className="container">
        <div className='content'>
          <hr/>
          <p className='is-size-4'>Recommendations</p>
          <p className='is-italic'>
            Marvs built the original LaunchPad product and then built a cohesive, loyal and strong team to extend and manage its evolution. He is an extremely skilled engineer, but he's also a lot more with true strengths as a leader. Marvs takes ownership, thinks strategically, has strong values, creates structure out of chaos and can be completely relied upon. He also has a great dry sense of humour which keeps me smiling :). Thanks for everything you did to make LaunchPad a reality.
          </p>
          <p>
            - <ExtLink href="https://www.linkedin.com/in/marvinbaltazar/details/recommendations/">Will Hamilton</ExtLink>, Founder & CEO of LaunchPad Recruits
          </p>
        </div>
      </div>
    </section>
  )
}


export default Recommendations;
